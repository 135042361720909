<template>
  <div class="content-div">
    <h1>Overview of the Liquor / Club Liquor Licence Application</h1>
    <p>Under the Dutiable Commodities (Liquor) Regulations (Cap.109B), any person seeking a liquor licence or club liquor licence, shall make application in writing to the Board in accordance with the form determined by the Liquor Licensing Board (LLB) - a statutory body established under Cap.109B who is responsible for the licensing of premises used for the sale or supply of liquor for consumption on the premises.</p>
    <p class="mt-3">LLB has given consents to accept applications and documents in form of electronic records. With effect from 1 August 2010, you may submit the application to the Licensing Offices of Food and Environmental Hygiene Department (FEHD) by filling the appropriate forms, attaching relevant information through electronic files and signing the forms digitally through the system.</p>
    <p class="mt-3 ">By Amendment to the Dutiable Commodities (Liquor) Regulation in 2015, it is a requirement to sign on the electronic form with a digital signature, or a password assigned or approved by LLB. LLB will accept electronic submission of applications for the issue, renewal, transfer or amendment of liquor licences if accompanied by either a password assigned or approved by the LLB or by a digital signature and this will be rolled out in two phases. <strong>Starting from July 2016, you may submit application for renewal or amendment of a liquor licence or club liquor licence online by a password assigned or approved by LLB other than a digital signature in the first phase</strong>. In the second phase, application for new issue, transfer and other type of applications of a liquor licence or club liquor licence are covered in October 2016.</p>
   
    
    <BoldLine class="mt-10" :options="{color:'#CEC3F0',height:'3px',width:'100%'}"></BoldLine>
    <v-expansion-panels multiple > 
      <OlsExpansion :order="special.order" title="General Requirements" :type="1">
        <p class="expansion">Online Licence Services on the Liquor Licence Processing System (LLPS) are provided under the provision of the Electronic Transaction Ordinance (ETO). You must have an account in the LLPS before being able to submit electronic forms. To ensure successful submission of electronic application, please note the following:</p>
        <div class="s-li">
            <div>•</div>
            <p>This service is designed to be used by Adobe Reader 9.2 or above, and Microsoft Internet Explorer version 6 (or above) on Windows platform (Microsoft Windows 2000, Microsoft Windows XP, Microsoft Windows Vista, Windows 7) or Mozilla Firefox version 3 (or above) on Linux platform, Mac OS X platform, and Windows platform (Microsoft Windows 2000, Microsoft Windows XP, Microsoft Windows Vista, Windows 7).</p>
        </div>
        <div class="s-li">
            <div>•</div>
            <p>Browser configurations including Javascript, Cookies, 28-bit SSL, and Java (for payment through internet PPS) must be enabled. For IE6.0 users, if you are going to settle payment through Internet PPS or with a HSBC or Hang Seng VISA credit card, you must change the default privacy settings of your browser in order to accept cookies from the relevant website. Otherwise you will not be able to complete the transaction.</p>
        </div>
        <div class="s-li">
            <div>•</div>
            <p>If you are going to settle payment by a Visa credit card, you must register the "Verified by Visa" service with your card issuing bank. Otherwise you may not be able to complete the transaction. Some Visa card and MasterCard issuers do not support online payment, please check with your credit card issuers to ascertain if they support online payment.</p>
        </div>
        <div class="s-li">
            <div>•</div>
            <p>To ensure a smooth payment process, please check if your computer's hardware, software and internet connection settings meet the following requirements of the system :</p>
        </div>
        <div class="ml-16">
            <p class="ml-6">Operation System: Windows XP<br/>
                Browser: Microsoft Internet Explorer Browser (7.0)<br/>
                Java Enabled: Yes<br/>
                Java Vendor and Version: Sun Microsystems 1.6.0_11<br/>
                JavaScript Enabled: Yes<br/>
                Cookie Enabled: Yes
            </p>
        </div>
        <div class="s-li">
            <div>•</div>
            <p>For correct display and input of Chinese Characters in Adobe Reader, Traditional Chinese version is recommended. Chinese Traditional Font Packs should be installed for non-Chinese version -> <a href="http://www.adobe.com/products/acrobat/acrrasianfontpack.html" target="_blank">http://www.adobe.com/products/acrobat/acrrasianfontpack.html</a>.</p>
        </div>
        <div class="s-li">
            <div>•</div>
            <p>For correct display of Chinese Characters in browsers, the computer must be able to display the Big-5 or ISO10646 character set with "the Hong Kong SupplSteps to Submit Application character set can be downloaded at  -> <a href="http://www.info.gov.hk/digital21/chi/hkscs/download.html" target="_blank">http://www.info.gov.hk/digital21/chi/hkscs/download.html</a>.</p>
        </div>
        <div class="s-li">
            <div>•</div>
            <p>You must have a valid digital certificate issued by the Hong Kong Post Office or any Certificate Authority recognized by the HKSAR Government.</p>
        </div>
        <div class="s-li">
            <div>•</div>
            <p>File attachments must not be larger than 5MB in total for each submission and must be compiled to the acceptable format.</p>
        </div>
      </OlsExpansion>
      <OlsExpansion :order="special.order" title="Acceptable File Formats" :type="1">
        <p class="expansion">The electronic files to be submitted should be in any of the following formats:</p>
        <div class="s-li">
            <div>•</div>
            <p>Plain text (TXT), which is a document format in which a sequence of characters and the words they form are encoded into computer-readable format using various types of language coding schemes. Most of the word processing software in the market allow users to save their document as plain text. Please note that when a document is saved as plain text, special effects such as font size, boldface, italics, underline, etc. will be lost.</p>
        </div>
        <div class="s-li">
            <div>•</div>
            <p>Microsoft Rich Text Format, which is promulgated by Microsoft Corporation. It is supported by the majority of word processing software in the market and can be used to submit files with formatting retained. To do so, choose "Save As" function and choose "Rich Text Format (RTF)" as the document type. Many word processing software not developed by the Microsoft Corporation also allow the saving of documents in RTF format. For the avoidance of doubt, documents in Microsoft Word format (with extension .doc) are not acceptable.</p>
        </div>
        <div class="s-li">
            <div>•</div>
            <p>Hypertext Mark Up Language Format. HTML is a document format promulgated by the World Wide Web Consortium for the content on web pages. Many word processing software in the market allow users to save their documents in HTML format. For the avoidance of doubt, documents with embedded Visual Basic code, Flash, Java scripts, or other embedded programming codes are not acceptable.</p>
        </div>
        <div class="s-li">
            <div>•</div>
            <p>Adobe Portable Document Format (compatible to Acrobat version 5 or above) promulgated by the Adobe Systems Incorporated. Tools can be used to convert textual documents into PDF format.</p>
        </div>
        <div class="s-li">
            <div>•</div>
            <p>Encapsulated PostScript Files (EPSF).</p>
        </div>
        <div class="s-li">
            <div>•</div>
            <p>Portable Network Graphics (PNG).</p>
        </div>
        <div class="s-li">
            <div>•</div>
            <p>Tag Image File Format (TIFF) promulgated by Adobe Systems Incorporated.</p>
        </div>
        <div class="s-li">
            <div>•</div>
            <p>Graphic Interchange File Format (GIF) promulgated by CompuServe Incorporated.</p>
        </div>
        <div class="s-li">
            <div>•</div>
            <p>Windows BitMaps (BMP).</p>
        </div>
        <div class="s-li">
            <div>•</div>
            <p>Joint Photographic Experts Group (JPEG) promulgated by the International Organisation for Standardisation (ISO) and the International Telecommunication Union (ITU).</p>
        </div>
        <p class="expansion">In respect of Computer Aided Design (CAD) drawings, an open standard - Autodesk Drawing Exchange Format (DXF) promulgated by Autodesk, Inc. is acceptable.</p>
      </OlsExpansion>
    </v-expansion-panels>
  </div>
  
</template>

<script>
import BoldLine from '../../components/misc/bold-line'
import OlsExpansion from '../../components/misc/ols-expansion'

export default {
  name: 'InOverview',
  components: {
    BoldLine,
    OlsExpansion,
  },
  data: function () {
    return {
      items:[
          
      ],
      special:{
          order: 11,
          title:'I cannot use PPS for online payment recently. I have been using PPS payment in the past. Why does this happen and what can I do?',
          content:'<img :src="logo" alt="Error message in Internet Explorer" width="234" height="294">'
      },
    }
  },
}
</script>

<style lang="scss">
p.expansion{
  strong.i{
       font-style: italic;
  }
  span{
    word-wrap: break-word;
  }
}
a{
      text-decoration: none !important;
    p{
      color:$ols-primary !important;
    }
    line-break: anywhere;
}

.s-li{
    display: flex;
    align-items: flex-start;
    margin-bottom:12px;
    div:first-child{
        color: $ols-secondary;
        font-weight: bold;
        font-size:2em;
        margin:-6.5px 16px 0px 20px;
    }
}

ul.expansion{
  margin-left:140px;
  
  li{
    margin-bottom:12px;
    p{
      margin:0px;
      span{
        word-wrap: break-word;
      }
    }
  }
}

img.expansion{
  margin-left:60px;
  margin-bottom:16px;
}
//md and down
@media screen and (max-width: 1263px) {
  
}

</style>